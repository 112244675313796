@import url('https://fonts.googleapis.com/css2?family=Koulen&family=Zen+Old+Mincho:wght@700&display=swap');

body {
    background-color: #D2D2D2;
    margin:0;
  }



.zen{
  font-family: 'Zen Old Mincho', serif;
}

.koulen{
  font-family: 'Koulen', cursive;
}